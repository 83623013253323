import { VERSION } from "./version";

export const environment = {
  MacroHelixSupportUrl: 'https://help.mhiapps.com/',
  production: true,
  appVersion: VERSION.version + "." + VERSION.raw + "-prod",
  apiBaseUrl: 'https://api.mhiapps.com/',
  baseUrl: 'https://app.mhiapps.com',
  loginUrl: 'https://mhiapps.com/',
  ssoLoginUrl: 'https://fedsvc.mckesson.com/adfs/ls/',
  subcriptionKey: '7b08b12da617447a83ed05a9e0482595',
  UAMUserApiEndpoint: "user/",
  appInsights: {
    instrumentationKey: 'd1dd25b8-edbd-4c8e-ad61-10c2a0915f09',
    applicationName: 'MHIApps'
  },
  b2c: {
    TenantName: "mhiapps",
    SignInUrl: "https://mhiapps.b2clogin.com/mhiapps.onmicrosoft.com/b2c_1a_mhiapps_emailonlysignin/oauth2/v2.0/authorize",
    ClientId: "bbf70887-0d3e-4f30-9774-4a6d5ec5e5a5",
    RedirectUrl: "https://web.mhiapps.com/",
    LogoutUrl: "https://mhiapps.com/",
    SignInPolicy: "B2C_1A_mhiapps_EmailOnlySignIn",
    OldSignInPolicy: "B2C_1A_mhiapps_JITMigrationSignIn",
    ResetPasswordPolicy: "B2C_1A_mhiapps_PasswordReset",
    LoggedInPasswordResetPolicyId: "B2C_1A_mhiapps_PasswordChange",
    UserChangeMFAPolicyId: "B2C_1A_mhiapps_MFAChange",
    UserApiEndpoint: "user/v1/login",
    SignUpPolicy: "B2C_1A_mhiapps_OnboardingSignUp",
    ConnectSignInPolicy: "B2C_1A_mhiapps_ConnectSignIn"
  },
  analyticsReportNames: '340BI Reporting,340BI Optimization,340BI Account Table',
  embeddingPageUrl: 'https://app.mhiapps.com/EmbeddingPage.aspx'
};
